import React from "react"
import styled from "styled-components"
import Footer from "../sections/Footer"
import { Title1, Title2, Title3, Title4, Text } from "../assets/primitives"
import { Container, Row, Col } from "react-grid-system"
import Layout from "../components/layout"

const appAgb20190509 = () => (
  <Layout showCookieBanner={false}>
    <ContainerExt>
      <Row>
        <Col xs={12}>
          <Title1>Nutzungsbedingungen der BRIVE Fleet Solutions GmbH</Title1>
          <br />
          <Text>Stand 9. Mai 2019</Text>
          <Title2>1. Leistungsgegenstand</Title2>
          <Text>
            1.1 Gegenstand dieser Nutzungsbedingungen ist das Vertragsverhältnis
            zwischen der BRIVE Fleet Solutions GmbH, Residenzstraße 37, 13409
            Berlin („BRIVE“, „wir“, „uns“, „unser“) und dem Nutzer der
            BRIVE-Applikation.
          </Text>
          <Text>
            1.2 BRIVE betreibt für seine Kunden („Arbeitgeber“) ein Fahranalyse-
            und Bonusprogramm für deren Mitarbeiter. Hierzu stellt BRIVE den
            Mitarbeitern seiner Kunden („Nutzer“) eine BRIVE-Applikation für
            mobile Endgeräte („BRIVE-App“) zur Erfassung des Fahrverhaltens mit
            dienstlich bereitgestellten Fahrzeugen zur Verfügung. BRIVE erstellt
            aus den erfassten Daten zum Fahrverhalten der einzelnen Nutzer
            aggregierte Auswertungen der gesamten Dienstfahrzeugflotte des
            Kunden und stellt ihm diese bereit. Die Auswertungen sind so
            beschaffen, dass sie dem Arbeitgeber ermöglichen, auf Verbesserung
            des Fahrverhaltens hinzuwirken und die Entwicklung zu überwachen.
          </Text>
          <Text>
            1.3 BRIVE betreibt für den Arbeitsgeber ein Bonusprogramm, in dem
            positives Fahrverhalten des Nutzers monatlich durch einen
            individuellen Bonus belohnt wird. BRIVE ermittelt den Bonus und
            unterstützt die Ausschüttung an den Nutzer. Die Ausschüttung selbst
            erfolgt durch den Arbeitgeber oder von diesem eingeschaltete
            Dienstleister.
          </Text>
          <Text>
            1.4 Die Nutzung der BRIVE-App sowie sämtliche Leistungen von BRIVE
            gemäß dieser Nutzungsbedingungen sind für den Nutzer kostenfrei.
          </Text>
          <Title2>2. Registrierung und technische Voraussetzungen</Title2>
          <Text>
            2.1 Die Teilnahme am Bonusprogramm des Arbeitgebers setzt die
            erfolgreiche Registrierung für BRIVE über die BRIVE-App voraus.
            Voraussetzung einer Registrierung ist, dass der Arbeitgeber des
            Nutzers eine Analyse- und Bonusprogramm-Vereinbarung mit BRIVE
            geschlossen hat und der Nutzer seinem Arbeitgeber zugeordnet werden
            kann.
          </Text>
          <Text>
            2.2 Bei der Registrierung hat der Nutzer sämtliche als Pflichtfeld
            gekennzeichneten Angaben vollständig, korrekt und wahrheitsgemäß
            vorzunehmen und am Ende des Registrierungsprozesses den Button
            "Registrieren" zu betätigen. Mit Abschluss der Registrierung kommt
            ein Nutzungsverhältnis mit uns auf Grundlage dieser
            Nutzungsbedingungen zustande. Diese Nutzungsbedingungen sind
            jederzeit in der BRIVE-App abrufbar.
          </Text>
          <Text>
            2.3 Der Nutzer hat dafür Sorge zu tragen, die im Rahmen der
            Registrierung angegebenen Daten stets aktuell zu halten und die
            Änderungen BRIVE rechtzeitig mitzuteilen.
          </Text>
          <Text>
            2.4 Die BRIVE-App kann über den Google Play Store oder den Apple App
            Store kostenfrei heruntergeladen werden. Voraussetzung für die
            Nutzung ist ein mobiles Endgerät mit Android- oder
            iOS-Betriebssystem (Android ab Version 5.0, iOS ab Version 10), eine
            dauerhafte und ausreichend schnelle Internetverbindung sowie
            genügend Speicherplatz. Das mobile Endgerät muss über folgende
            funktionierenden Hardwarekomponenten verfügen:
            <br />
            <ul>
              <li>GPS-Modul</li>
              <li>Beschleunigungssensor</li>
              <li>Gyroskop</li>
              <li>Magnetometer</li>
            </ul>
            Das Fehlen oder der Ausfall einer dieser Komponenten führt dazu,
            dass die BRIVE-App nicht nutzbar ist.
          </Text>
          <Text>
            2.5 BRIVE ist jederzeit berechtigt, die BRIVE-App zu verändern, zu
            erweitern und anzupassen, insbesondere durch Einspielen von Updates,
            neuen Versionen oder sonstigen Weiterentwicklungen. Sofern hiermit
            gesteigerte Systemanforderungen an die mobilen Endgeräte der Nutzer
            verbunden sind, teilt BRIVE dies in den Installationshinweisen vor
            dem Einspielen der Weiterentwicklung mit.
          </Text>
          <Title2>3. Analyse und Bonusermittlung</Title2>
          <Text>
            3.1 Um am Bonusprogramm des Arbeitgebers teilzunehmen, muss der
            Nutzer die BRIVE-App für eine vom Arbeitgeber festgelegte Anzahl an
            Fahrstunden pro Monat nutzen („Fahrzeit“).
          </Text>
          <Text>
            3.2 Ist die BRIVE-App aktiviert, erkennt diese automatisch, wenn
            eine Fahrt durchgeführt wird. Nur die Daten einer erkannten Fahrt
            werden an BRIVE zur Analyse übermittelt. Alle außerhalb einer
            festgestellten Fahrt erhobenen Daten werden lediglich lokal auf dem
            mobilen Endgerät des Nutzers gespeichert und später gelöscht.
          </Text>
          <Text>
            3.3 Als Fahrzeit wird nur die Zeit berücksichtigt, welche die
            BRIVE-App als Fahrt registriert. Der Nutzer kann die Erfassung
            jederzeit ausschalten oder pausieren.
          </Text>
          <Text>
            3.4 BRIVE bewertet insbesondere folgende Parameter:
            <ul>
              <li>Anfahrverhalten</li>
              <li>Bremsverhalten</li>
              <li>Geschwindigkeit</li>
              <li>Handynutzung</li>
              <li>Kurvenverhalten</li>
              <li>
                Verbrauch (Kombination aus Geschwindigkeit, Bremsverhalten und
                Anfahrverhalten)
              </li>
            </ul>
          </Text>
          <Text>
            3.5 Die erfassten Werte des Nutzers werden dabei einzeln oder mit
            denen aller weiteren Nutzer des gleichen Arbeitgebers kombiniert und
            diesem als Auswertung in Bezug auf die gesamte Fahrzeugflotte zur
            Verfügung gestellt.
          </Text>
          <Text>
            3.6 Im Rahmen der Auswertung des Fahrverhaltens kann BRIVE auch
            Verkehrsverstöße des Nutzers erfassen, wie insbesondere
            Geschwindigkeitsüberschreitungen. Diese fließen in die
            Gesamtauswertung ein.
          </Text>
          <Text>
            3.7 BRIVE ermittelt aufgrund des erfassten Fahrverhaltens monatlich
            einen internen Scorewert für den Nutzer. Entsprechend der Abrede mit
            dem Arbeitgeber über die Ausgestaltung des Bonusprogramms, misst
            BRIVE diesem Scorewert einen Geldbetrag oder einen anderen Bonus
            („Bonuswert“) zu und übermittelt diese Information an den
            Arbeitgeber. Sofern der Arbeitgeber zugleich einen Vertrag mit
            unseren Service-Partnern über die Ausschüttung des Bonus geschlossen
            hat, leitet BRIVE den Bonuswert unmittelbar an diesen
            Service-Partner weiter und unterstützt die Ausschüttung.
          </Text>
          <Text>
            3.8 Im Falle einer Einbeziehung eines Service-Partners, ist die
            Ausschüttung des ausgelobten Bonus an die Akzeptanz der gesonderten
            Bedingungen des Service-Partners durch den Nutzer geknüpft.
          </Text>
          <Title2>4. Pflichten des Nutzers</Title2>
          <Text>
            4.1 Der Nutzer ist dafür verantwortlich, dass die BRIVE-App auf
            seinem mobilen Endgerät fehlerfrei und ordnungsgemäß funktioniert.
            Er hat hierfür die Tracking-Funktion der BRIVE-App ordnungsgemäß und
            vollumfänglich aktiviert. Die vollständige Funktionsfähigkeit der
            Erhebung erfordert neben der Erteilung der datenschutzrechtlichen
            Einwilligungen im Registrierungsprozess auch die Erteilung
            sämtlicher vorgesehener Zugriffsberechtigungen in der BRIVE-App.
            Sobald der Nutzer das Tracking in der BRIVE-App aktiviert, wird sein
            Fahrverhalten über die Hardwarekomponenten nach Ziff. 2.4. des
            mobilen Endgerätes von BRIVE erfasst und analysiert.
          </Text>
          <Text>
            4.2 Der Nutzer darf die BRIVE-App nur gemäß dieser
            Nutzungsbedingungen und nicht missbräuchlich nutzen. Insbesondere
            hat er zu gewährleisten, dass die Auswertung nur bei Fahrten
            <ul>
              <li>mit dem Dienstfahrzeug und</li>
              <li>bei denen er selbst das Fahrzeug führt</li>
            </ul>
            aktiviert ist. Der Nutzer hat BRIVE unverzüglich zu informieren,
            wenn er feststellt, dass das Fahrverhalten einer anderen Person
            erfasst und ausgewertet wurde.
          </Text>
          <Text>
            4.3 Der Nutzer darf nur Fahrten erfassen, die nicht ausschließlich
            dem Zwecke des Erzielens eines Bonus dienen.
          </Text>
          <Text>
            4.4 Der Nutzer darf die BRIVE-App während einer Fahrt nicht
            bedienen.
          </Text>
          <Title2>5. Nutzung für eigene Zwecke</Title2>
          <Text>
            5.1 BRIVE ist berechtigt, die im Rahmen der Auswertung gewonnenen
            Daten des Nutzers im Sinne von Erwägungsgrund 26 der DSGVO zu
            anonymisieren und für eigene Zwecke zu verwenden. Die eigenen Zwecke
            von BRIVE umfassen insbesondere die technische und inhaltliche
            Analyse und Verbesserung der eigenen Leistung sowie die Vermarktung
            der Erkenntnisse über Auslastung und allgemeines Fahrverhalten.
            Hierzu werden ausschließlich anonyme Daten wie z. B. gefahrene
            Teilstrecken analysiert, Rückschlüsse auf den Nutzer sind
            ausgeschlossen. Die Ergebnisse werden von BRIVE verwendet,
            aufbereitet und gegebenenfalls Dritten zugänglich gemacht.
          </Text>
          <Title2>6. Haftung</Title2>
          <Text>
            6.1 BRIVE haftet für Schäden, die durch eine Fehlerhaftigkeit der
            BRIVE-App entstehen, lediglich für Vorsatz und grobe Fahrlässigkeit
            sowie bei arglistigem Verschweigen eines Mangels. Dies gilt
            insbesondere, wenn infolge einer Fehlerhaftigkeit der BRIVE-App die
            Erfassung des Fahrverhaltens ganz oder teilweise unterbleibt oder
            fehlerhafte Werte erfasst werden.
          </Text>
          <Text>
            6.2 BRIVE analysiert das Fahrverhalten und berechnet den Bonus
            ausschließlich anhand der Signale des mobilen Endgerätes. BRIVE ist
            nicht verantwortlich für die Richtigkeit, Genauigkeit oder
            Vollständigkeit der übermittelten Daten. BRIVE haftet auch nicht für
            Störungen der Erfassung, die durch die Hardware des mobilen
            Endgerätes oder einen Abbruch der Internetverbindung verursacht
            werden.
          </Text>
          <Text>
            6.3 BRIVE haftet nicht für Schäden des Nutzers aufgrund der Nutzung
            der App während der Fahrt.
          </Text>
          <Text>
            6.4 In allen übrigen Fällen gelten die nachfolgenden Regelungen zur
            Haftung von BRIVE für Schadensersatz- oder an dessen Stelle
            tretenden sonstigen Ersatzansprüche des Nutzers aus /oder im
            Zusammenhang mit der Durchführung dieses Vertrages und Haftungsfälle
            unabhängig davon, auf welchem Rechtsgrund sie beruhen (z. B. Verzug,
            Unmöglichkeit, jegliche Pflichtverletzung, Vorliegen eines
            Leistungshindernisses, unerlaubte Handlung etc.).
          </Text>
          <Text>
            6.5 Für Ansprüche des Kunden
            <ul>
              <li>
                wegen Fehlens einer Beschaffenheit, für die BRIVE eine Garantie
                übernommen hat,
              </li>
              <li>
                die auf einem vorsätzlichen oder grob fahrlässigen Verhalten von
                BRIVE, ihren Organen oder leitenden Mitarbeitern beruhen sowie
              </li>
              <li>nach dem Produkthaftungsgesetz</li>
            </ul>
            haftet BRIVE nach den gesetzlichen Regelungen.
          </Text>
          <Text>
            6.6 Bei einfacher Fahrlässigkeit haftet BRIVE bei Verletzung einer
            Pflicht, die für die Durchführung des Vertrages wesentlich ist, nur
            für den typischen und vorhersehbaren Schaden. Im Übrigen ist die
            Haftung von BRIVE ausgeschlossen.
          </Text>
          <Text>
            6.7 Der vorhersehbare Schaden gemäß Ziff. 6.5. ist der Höhe nach
            begrenzt auf 10000 EUR pro Schadensfall und auf 80000 EUR pro
            Vertragsjahr.
          </Text>
          <Title2>7. Datenschutz</Title2>
          <Text>
            7.1 BRIVE verarbeitet die personenbezogenen Daten nach den
            gesetzlichen Vorgaben, insbesondere der Datenschutz-Grundverordnung
            (DSGVO) und des Bundesdatenschutzgesetzes (BDSG). Alle Mitarbeiter
            von BRIVE wurden auf die Vertraulichkeit der personenbezogenen Daten
            verpflichtet. BRIVE kann für Teile der Datenverarbeitung
            Auftragsverarbeiter einschalten.
          </Text>
          <Text>
            7.2 Die Auswertung erfordert eine permanente Erfassung von
            technischen Daten des mobilen Endgerätes des Nutzers, inklusive
            dessen Standort. Eine Übermittlung an BRIVE erfolgt nur, wenn die
            BRIVE-App eine Fahrt registriert.
          </Text>
          <Text>
            7.3 Die automatische Ermittlung des Bonuswertes anhand des erfassten
            Fahrverhaltens stellt eine automatisierte Entscheidungsfindung nach
            Art. 22 DSGVO dar.
          </Text>
          <Text>
            7.4 Der Nutzer erteilt für die erforderlichen
            Datenverarbeitungsprozesse im Rahmen des Vertragsschlusses eine
            ausdrückliche Einwilligung. Diese Einwilligung ist Bedingung für die
            Durchführung des Vertrages. Die Einwilligung kann jederzeit mit
            Wirkung für die Zukunft widerrufen werden. Der Widerruf der
            Einwilligung führt automatisch zur Kündigung dieser Vereinbarung.
          </Text>
          <Text>
            7.5 Der Nutzer teilt BRIVE unverzüglich alle Umstände mit, die dazu
            führen könnten, dass er seine Entscheidung über die Teilnahme nicht
            freiwillig trifft.
          </Text>
          <Text>
            7.6 Grundlage der Bereitstellung der BRIVE-App an den Nutzer ist
            eine Vereinbarung mit dem Arbeitgeber zur Auswertung des
            Fahrverhaltens der von diesem bereitgestellten Dienstfahrzeuge.
            Hierfür ist die Übermittlung bestimmter Daten an den Arbeitgeber
            erforderlich. Diese Übermittlung erfolgt sowohl zur Erfüllung der
            Nutzungsvereinbarung mit dem Nutzer als auch des Vertrages mit dem
            Arbeitgeber.
          </Text>
          <Text>
            7.7 Die Auswertung des Fahrverhaltens für einen Arbeitgeber erfolgt
            insgesamt auf Flottenbasis für aller ihm zuordenbaren Nutzern. BRIVE
            kann jedoch nicht gewährleisten, dass die übermittelten
            Informationen zum Fahrverhalten dadurch in jedem Fall anonymisiert
            oder pseudonymisiert sind und gibt auch keine entsprechende Garantie
            ab. Im Einzelfall kann nicht ausgeschlossen werden, dass aus dem
            Ergebnis Rückschlüsse auf einen einzelnen Nutzer möglich sind. Zudem
            erfährt der Arbeitgeber auch den Zahlbetrag an den Nutzer, der
            wiederum Rückschlüsse auf dessen Fahrverhalten geben könnte.
          </Text>
          <Text>
            7.8 Erfolgt die Auszahlung über einen Service-Partner, ist BRIVE
            berechtigt, auch diesem bestimmte Informationen zum Nutzer,
            insbesondere den monatlichen Zahlbetrag, zu übermitteln.{" "}
          </Text>
          <Title2>8. Laufzeit und Kündigung</Title2>
          <Text>
            8.1 Diese Vereinbarung kommt mit Abschluss der Registrierung
            zustande und läuft auf unbestimmte Zeit. Sie kann von jeder Partei
            jederzeit ohne Einhaltung einer Frist gekündigt werden.
          </Text>
          <Text>
            8.2 Der Nutzer kann die Vereinbarung durch Löschen seines Accounts
            in den Einstellungen der BRIVE-App oder durch Mitteilung in Textform
            kündigen. Das bloße Löschen der BRIVE-App führt nicht zur Kündigung
            dieser Vereinbarung.
          </Text>
          <Text>
            8.3 Das Ende der Vereinbarung mit dem Arbeitgeber des Nutzers führt
            automatisch zum Ende der Nutzungsvereinbarung.
          </Text>
          <Title2>9. Sonstiges</Title2>
          <Text>
            9.1 Sollte eine Bestimmung dieses Vertrages unwirksam sein oder
            werden oder sollte der Vertrag unvollständig sein, wird der Vertrag
            im Übrigen nicht berührt. Die Vertragspartner werden die unwirksame
            Bestimmung durch eine solche Bestimmung ersetzen, welche dem Sinn
            und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise am
            nächsten kommt. Dasselbe gilt für Vertragslücken.
          </Text>
          <Text>
            9.2 Dieser Vertrag unterliegt dem Recht der Bundesrepublik
            Deutschland ohne die Re gelungen, die zur Anwendbarkeit
            ausländischen Rechts oder des CISG führen.
          </Text>
        </Col>
      </Row>
    </ContainerExt>
  </Layout>
)

export default appAgb20190509

const ContainerExt = styled(Container)`
  padding: 3rem 0;
`
